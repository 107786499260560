import React from "react";
// import FooterTwo from '../Components/Footer/FooterTwo'
// import HeaderOne from '../Components/Header/HeaderOne'
// import bannerBg from "../assets/img/page-banner.jpg";
import BannerImg from "../Components/Images/banner/about-us-new.jpg";
import PageBanner from "../Components/PageBanner";
// import AboutOne from "../Components/About/AboutOne";
import AboutTwo from "../Components/About/AboutTwo";
// import AboutBanner from "../Components/About/AboutBanner";
// import Timeline from "../Components/Timeline";
// import TeamThree from "../Components/Team/TeamThree";
// import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
// import Skill from "../Components/Skills/Skill";
// import Facts from "../Components/FunFact/FunFact";
import FunFact from "../Components/FunFact/FunFact";
import MissionSection from "../Components/About/Mission";
import VisionSection from "../Components/About/Vision";
import ValueSectionHead from "../Components/About/ValuesHead";
import ValueSection from "../Components/About/Values";
import Keypoints from "../Components/About/Keypoints";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Prem Industries India Limited</title>
        <meta name="description" content="About Us" />
      </Helmet>
      {/* <HeaderOne/> */}
      <PageBanner title="About us" bannerBg={BannerImg} currentPage="About" />
      {/* <AboutOne pt /> */}
      <AboutTwo
        subhead="About"
        head="Prem Industries India Limited"
        text1="Goel Group's foundation was laid in 1975 by the Chairman Mr. Ved Prakash Goel. Goel Group is now a diversified organization in India with focus on B2B sector. It has created a big footprint in northern India with a portfolio comprising from packaging, steel, retail and construction as the main drivers of growth. Headquartered in Uttar Pradesh, India, it has positioned itself to be the North Indian market leader in packaging and steel trading businesses focusing on large scale development in the region with O&M practices benchmarked to global standards."
        verticalText="ABOUT"
        text2="The group owes it success and leadership position to its core philosophy of ‘nation building’ driven by ‘growth with goodness’- a guiding principle for sustainable growth."
        text3="Out of our 25 lakh square feet covered area, 20 lakh square feet are in prime locations across Delhi NCR catering to meet various demands."
      />
      <div
        className="container-fluid text-white"
        style={{ backgroundColor: "#14254C" }}
      >
        <FunFact />
      </div>
      <div className="container-fluid mt-5"></div>
      <MissionSection />
      <VisionSection />
      <div className="container-fluid mb-5"></div>
      <ValueSectionHead />
      <ValueSection />
      <Keypoints />
      {/* <AboutBanner /> */}
      {/* <Timeline /> */}
      {/* <Skill /> */}
      {/* <TeamThree /> */}
      {/* <BrandCarouselThree /> */}
      {/* <FooterTwo/> */}
    </>
  );
};

export default AboutPage;
