import React from "react";
// import FooterTwo from "../Components/Footer/FooterTwo";
// import HeaderOne from "../Components/Header/HeaderOne";
// import bannerBg from "../assets/img/page-banner.jpg";
import ClientsBanner from "../Components/Images/banner/our-clients-new.jpg";
import PageBanner from "../Components/PageBanner";
import PackagingSectorImg from "../Components/Images/PackagingSector.jpg";
import RetailSectorImg from "../Components/Images/RetailSector.jpg";
import SteelSectorImg from "../Components/Images/SteelSector.jpg";
import ConstructionSectorImg from "../Components/Images/ConstructionSector.jpg";
// import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
// import ContactPageContents from "../Components/Contact/ContactPageContents";
import ClientPageContents from "../Components/Client/ClientPageContent";
import { Helmet } from "react-helmet";

const ClientPage = () => {
  return (
    <>
      <Helmet>
        <title>Clients - Prem Industries India Limited</title>
        <meta name="description" content="Our Clients" />
      </Helmet>
      <PageBanner
        title="Our Clients"
        bannerBg={ClientsBanner}
        currentPage="contact"
      />
      <ClientPageContents
        maintitle="For in-depth contact us information, visit each specific sector."
        email="info@premindustries.in"
        number="+918447247227"
        address="C-209, Bulandshahr Road Industrial Area, Ghaziabad, Uttar Pradesh 201009"
        CardTitle1="Packaging Sector"
        CardTitle2="Steel Sector"
        CardTitle3="Retail Sector"
        CardTitle4="Construction Sector"
        CardImage1={PackagingSectorImg}
        CardImage2={SteelSectorImg}
        CardImage3={RetailSectorImg}
        CardImage4={ConstructionSectorImg}
        CardLink1="/"
        CardLink2="/"
        CardLink3="/"
        CardLink4="/"
      />
    </>
  );
};

export default ClientPage;
