import React from "react";
import CountUp from "react-countup";

const FunFact = () => {
  return (
    <section className="fun-fact-wrapper pt-70 pb-100 text-center wow fadeInLeft animated">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6 mt-30 col-12">
            <div className="single-fun-fact">
              <h2>
                <CountUp end={49} duration={10}></CountUp>+
              </h2>
              <h3>Years of Experience</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-30 col-12">
            <div className="single-fun-fact">
              <h2>
                <CountUp end={100} duration={10}></CountUp>+
              </h2>
              <h3>Advanced Machinery</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-30 col-12">
            <div className="single-fun-fact">
              <h2>
                <CountUp end={2000} duration={10}></CountUp>+
              </h2>
              <h3>B2B Partners</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-30 col-12">
            <div className="single-fun-fact">
              <h2>
                <CountUp end={1000} duration={10}></CountUp>+
              </h2>
              <h3>Professionals</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
